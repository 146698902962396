.formone{
    height: auto;
    width: 100vw;
  }
  .formsection{
    height: auto;
    width: 100vw;
    display: flex;
    
  }
  .formsection-right-sec-inphd{
    width: 30%;
  }
  .formsection-right-sec-inp{
    width: 60%;
    height: 6vh;
    width: 100%;
    border: 2px solid black;
    background-color: white;
    display: flex;
    flex-direction: row-reverse;
  
  }
   .formsection-right-sec-inp input {
    background-color: transparent;
    border: 0px solid;
    height: 100%;
    width: 80%;
    outline: none;
}
  .formsection-left{
    height: 90vh;
    width: 50vw;
    background-color: #E5E3F3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
   
  }
  .formsection-right{
    height: 90vh;
    width: 50vw;
    background-color: #E5E3F3;
    padding-left:5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .frmsecleft{
    display: flex;
    flex-direction:row ;
    text-align: center;
    font-size: larger;
    font-style: italic;
  }
  .frmsecleft-tx{
    width: 30%;
    text-align: right;
    padding-right: 5%;
  }
  .frmsecleft-drpdwn{
    width: 100%;
  }
  .frmsecleft-txprt{
    margin-left: 30%;
    width: 60%;
    text-align: left;
    font-style: italic;
  }
  .expr-prc{
    display: flex;
    margin-left: 30%;
   gap: 5vw;
  }
  .frmsecleft-btn-div{
    margin-left: 30%;
    width: 60%;
  }
  .frmsecleft-btn-div-frm2{
    margin-left: 10%;
    width: 80%;
    
  }
  .frmsecleft-btn-div-frm2>button{
    width: 100%;
    height: 2rem;
    color:white;
    background-color: #093FAA;
    border: transparent;
    font-weight: 500;
    /* font-size: 1.2rem; */
    
    }
  .frmsecleft-btn-div>button{
  width: 100%;
  height: 2rem;
  color:white;
  background-color: #093FAA;
  border: transparent;
  font-weight: 500;
  /* font-size: 1.2rem; */
  
  }
  .formsection-right-sec-hd{

   display: flex;
   justify-content: center;
   align-items: center;
  }
  .formsection-right-sec-bd{
    text-align: left;
    margin-left: 15%;
    font-style: italic;
  }
  .formsection-right-sec-2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   width: 100%;
   font-weight: 1000;
   /* font-size: 1.4rem; */
  }
  .formsection-right-sec{
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  .inp-field{
 border: none;
 width: 80%;
  }
  .formsection-right-sec-3{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    /* font-size: 1.4rem; */
  }
  /* .formsection-right-sec-3{ 

  } */
  .btn-div>button{
width:100%;
color: white;
background-color: #093FAA;
border: transparent;
height: 2rem;
/* font-size: 1.2rem; */
  }

  .textbg{
    font-size: 1.5rem;
    font-weight: 1000;
  }
  
.frmsecleft-dt{
  margin:10%;
height: 30vh;
  width: 80%;
  background-color: white;
}

.formsection-right-chart{
  height: 90vh;
  width: 50vw;
  background-color: #E5E3F3;
  padding-left:5%;
  padding-right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frmsecleft-amtmnt{
  display: flex;
}

.text-bg-2{
  font-weight: 1000;
}
.inp-field-lngtoken{
  width: 100%;
  border: none;
}


.drdnrsltbx{
  width: 70px;
  /* height: 5vh; */
  text-align: center;
  border-radius: 10px;
  font-size: large;
  box-shadow: .5px .5px .5px 1px lightgray;
}
.drdnrsltbx-2{
  width: 170px;
  /* height: 5vh; */
  text-align: center;
  border-radius: 10px;
  font-size: large;
  box-shadow: .5px .5px .5px 1px lightgray;
}
