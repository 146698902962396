
.banner{
  height: 6vh;
  background-color: #F7A2A3;
  display: flex;
  flex-direction: row;
  color: brown;
}

.banner>div>button{
  height: 90%;
  background-color:#CE027D;
  color: white;
  border:1px solid red;

}
.banner>div{
  width: 50%;
}

.text-banner{
  display: flex;
  justify-content: center;
  padding-top: 1vh;
}
.buttondiv{
text-align: end;
margin: auto;
margin-right: 20px;
}
.btnbannerpart{
  display: flex;
  align-items: center;
  justify-content: end;
}
.btnbannerpart>button{
background-color: rgb(255, 6, 139);
color: white;
border: rgb(255, 6, 139);;
}
.header{
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
 
}
.cnctwltbtn{
  width: 100%;
  height: 40px;
  color:#093FAA;
  background-color: white;
  box-shadow: 0 0 4px 0px #093FAA;
  border: white;
}
.cnctwltbtn:hover{
background-color:#093FAA;
color: white;
}
.btnrfrnw{
  width: 100%;
  height: 40px;
  color:white;
  background-color:#093FAA;
  border:#093FAA;
}
.loading{
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbarlink{
  text-decoration: none;
  font-size: larger;
  color:  gray;
  font-weight: 1000;
}

  .loading_spinner{
    position: fixed;
    height: 100%;
    width:100%;
    background:white;
    top: 0;
    z-index: 100000;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: gray;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }